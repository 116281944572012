<script setup lang="ts">
import { useMenuBuilder } from "@/composables/useMenuBuilder"
import { useNavbarState } from "@/stores"
import { useLocaleChooser } from "@/stores/useLocaleChooser"
import { useApp, useAppConfig, useToggleableValue } from "@/vf"
import AnimateIf from "@/vf/components/AnimateIf.vue"
import { storeToRefs } from "pinia"
import { watch } from "vue"
import RotateChevron from "../RotateChevron.vue"
import VarsityContentProcessor from "../VarsityContentProcessor.vue"
import DismissableBackdrop from "./DismissableBackdrop.vue"

const navbarState = useNavbarState()
const { showMobileSidebar } = storeToRefs(navbarState)
const { toggleMobileSidebar } = navbarState
const isProduction = import.meta.env.VITE_ENVIRONMENT === "live"

const app = useApp()
const { appConfig } = useAppConfig()
const localeChooser = useLocaleChooser()
const { currentLocale, showFakeLocale } = storeToRefs(localeChooser)
const { switchLocale, fakeLocale } = localeChooser

const { menuItems, myOfficeRoute, myAdminRoute, getFirstChildRoutingData } = useMenuBuilder()

function close() {
    toggleMobileSidebar(false)
}

function logout() {
    close()
    app.logout()
}

function login() {
    navbarState.openCard("login")
    close()
}

/*─────────────────────────────────────┐
│  submenu                             │
└─────────────────────────────────────*/
const [openSubmenu, toggleSubmenu] = useToggleableValue()
watch(showMobileSidebar, () => {
    openSubmenu.value = null

    for (const item of menuItems.value) {
        if (item.hasActiveChildren) {
            openSubmenu.value = item.id
            break
        }
    }
})
</script>

<template>
    <div class="mobile-sidebar" :class="{ active: showMobileSidebar }">
        <template v-for="item of menuItems">
            <div v-if="!item.settings.hideInRootNavigation" class="nav-item">
                <div class="d-flex align-items-center">
                    <RouterLink
                        :to="getFirstChildRoutingData(item)"
                        class="nav-link flex-grow-1"
                        @click="close"
                        :class="{ active: item.hasActiveChildren }"
                    >
                        <VarsityContentProcessor :content="$t(item.label)"></VarsityContentProcessor>
                    </RouterLink>
                    <div>
                        <RotateChevron :open="openSubmenu === item.id" @click.stop="toggleSubmenu(item.id)" />
                    </div>
                </div>

                <div class="second-level">
                    <AnimateIf :if="openSubmenu === item.id">
                        <template v-for="child of item.children">
                            <div v-if="child.label" class="nav-item">
                                <RouterLink :to="getFirstChildRoutingData(child)" class="nav-link" @click="close">
                                    <VarsityContentProcessor :content="$t(child.label)"></VarsityContentProcessor>
                                </RouterLink>
                            </div>

                            <div v-if="child.children" class="third-level">
                                <template v-for="subchild of child.children">
                                    <div v-if="subchild.label" class="nav-item">
                                        <RouterLink
                                            :to="getFirstChildRoutingData(subchild)"
                                            class="nav-link"
                                            @click="close"
                                        >
                                            <VarsityContentProcessor
                                                :content="$t(subchild.label)"
                                            ></VarsityContentProcessor>
                                        </RouterLink>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </AnimateIf>
                </div>
            </div>
        </template>

        <template v-if="appConfig.login">
            <hr />
            <h6>My Varsity EU</h6>

            <div class="nav-item">
                <RouterLink class="nav-link" to="/my-varsity/my-profile" @click="close">My Profile</RouterLink>
            </div>

            <div class="nav-item" v-if="myOfficeRoute">
                <RouterLink class="nav-link" :to="myOfficeRoute" @click="close">My Office</RouterLink>
            </div>

            <div class="nav-item" v-if="myAdminRoute">
                <RouterLink class="nav-link" :to="myAdminRoute" @click="close">My Admin</RouterLink>
            </div>
        </template>

        <hr />
        <button
            :class="{ active: locale.iso == currentLocale }"
            @click="switchLocale(locale.iso, 'en')"
            class="locale-button"
            v-for="locale in appConfig.languages"
        >
            <i class="fa fa-fw fa-globe-europe mr-2"></i>
            {{ locale.name }}
        </button>
        <button
            :class="{ active: fakeLocale == currentLocale }"
            @click="switchLocale(fakeLocale, fakeLocale)"
            class="locale-button"
            v-if="showFakeLocale"
        >
            Language Keys
        </button>

        <template v-if="!isProduction || appConfig.login">
            <hr />
            <button v-if="appConfig.login" @click="logout" class="btn btn-link">
                <i class="fa fa-fw fa-sign-out-alt"></i>
                Logout
            </button>
            <button v-else @click="login" class="btn btn-link btn-block m-0">
                <i class="fa fa-fw fa-sign-in-alt"></i>
                Login
            </button>
        </template>
    </div>

    <DismissableBackdrop :active="showMobileSidebar" @close="toggleMobileSidebar(false)" />
</template>

<style scoped>
.mobile-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 90vw;
    max-width: 300px;
    height: 100%;
    z-index: 99;
    transition: 300ms ease-in-out transform;
    transform: translateX(100vw);

    background-color: #0b6fb9;
    color: #fff;
    padding: 2rem 0.5rem 2rem 2rem;
}

.second-level {
    margin-right: -0.5rem;
    margin-left: -2rem;
    background-color: var(--primary-dark);
    padding-left: 3rem;
}

.third-level {
    margin-right: -0.5rem;
    margin-left: -2rem;
    padding-left: 4rem;
}

.mobile-sidebar .nav-item {
}

.mobile-sidebar .nav-link {
    color: #ddd;
}

.mobile-sidebar .nav-link:hover {
    color: #fff;
}

.mobile-sidebar .nav-link.active {
    color: #0d2030;
}

.mobile-sidebar h6 {
    color: #ddd;
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 0.75rem;
}

.mobile-sidebar.active {
    transform: translateX(0);
}

.locale-button {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    color: #fff;
    border: none;
    background-color: transparent;
    padding: 0.5rem 1rem;
}

.locale-button.active {
    color: var(--primary-dark);
}

@media screen and (min-width: 992px) {
    .mobile-sidebar {
        display: none;
    }
}
</style>
ht
